import React, { Component } from "react";
import { connect } from "react-redux";

import CandyPower from "./CandyPower";

import * as actions from "../Actions/gameActions";

import "./Styles/PowersPanel.css";

class PowersPanel extends Component {
  handleOnClick = (type) => {
    switch (type) {
      case "ADD":
        if (this.props.powers.add > 0) this.props.dispatch(actions.showPopUpPowers(type));
        break;
      case "REPLACE":
        if (this.props.powers.replace > 0) this.props.dispatch(actions.showPopUpPowers(type));
        break;
      case "REMOVE":
        if (this.props.powers.remove > 0) this.props.dispatch(actions.showPopUpPowers(type));
        break;
      default:
        break;
    }
  };

  render() {
    return (
      <div className="PowersPanel">
        <span>powers</span>
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
          <CandyPower type="ADD" number={this.props.powers.add} handleOnClick={() => this.handleOnClick("ADD")} />
          <CandyPower type="REPLACE" number={this.props.powers.replace} handleOnClick={() => this.handleOnClick("REPLACE")} />
          <CandyPower type="REMOVE" number={this.props.powers.remove} handleOnClick={() => this.handleOnClick("REMOVE")} />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, props) {
  return {
    powers: state.game.powers,
  };
}

export default connect(mapStateToProps)(PowersPanel);
