import refillRow from "./refillRow";

const refillSpaces = (lvl, elements, powerRemoveActive) => {
  let existingElements = [];
  elements.forEach((element) => existingElements.push(element.id));
  for (let index = 3; index >= 0; index--)
    elements = refillRow(existingElements, lvl, elements, 3, index, powerRemoveActive);
  return elements;
};

export default refillSpaces;
