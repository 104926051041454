import React from "react";

import { useAuth0 } from "@auth0/auth0-react";
import { connect } from "react-redux";

import * as actions from "../Actions/gameActions";

const ButtonSaveRecord = (props) => {
  const { isAuthenticated, loginWithPopup } = useAuth0();

  const SaveRecord = () => {
    if (isAuthenticated) {
      props.dispatch(actions.setView("SaveRecord"));
    } else {
      // props.dispatch(actions.setLoading(true));
      loginWithPopup()
        .then(
          () => {
            setTimeout(() => {
              props.dispatch(actions.setView("SaveRecord"));
            }, 1000);
          },
          () => {
            setTimeout(() => {
              props.dispatch(actions.setLoading(false));
              props.dispatch(actions.setView("GameOver"));
            }, 100);
          }
        )
        .catch((error) => {
          props.dispatch(actions.setLoading(false));
          props.dispatch(actions.setView("GameOver"));
        });
    }
  };

  return (
    <button onClick={() => SaveRecord()} className="GameBtn">
      save my record
    </button>
  );
};

function mapStateToProps(state, props) {
  return {
    lvl: state.game.lvl,
    score: state.game.scoreGlobal,
    user: state.game.user,
  };
}

export default connect(mapStateToProps)(ButtonSaveRecord);
