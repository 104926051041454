import getNewElement from "./getNewElement";

const refillRow = (existingElements, lvl, elements, x, y, powerRemoveActive) => {
  if (x > 0) {
    if (elements[x][y].show === false && elements[x][y].isTemporary) {
      elements[x][y] = getNewElement(existingElements, lvl, 0.8, powerRemoveActive);
      refillRow(existingElements, lvl, elements, x - 1, y, powerRemoveActive);
    } else {
      refillRow(existingElements, lvl, elements, x - 1, y, powerRemoveActive);
    }
  } else {
    if (elements[x][y].show === false) {
      elements[x][y] = getNewElement(existingElements, lvl, 0.8, powerRemoveActive);
    }
  }

  return elements;
};

export default refillRow;
