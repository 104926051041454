import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../Actions/gameActions";
import "./Timer.css";

const millisToMinutesAndSeconds = (millis) => {
  var minutes = Math.floor(millis / 60000);
  var seconds = ((millis % 60000) / 1000).toFixed(0);
  return minutes + ":" + (seconds < 10 ? "0" : "") + seconds;
};

class Timer extends Component {
  runTimer = () => {
    if (this.props.play && this.props.time > 0) {
      let milliseconds = this.props.time - 500;
      this.props.dispatch(actions.setTime(milliseconds));
      setTimeout(this.runTimer, 500);
    } else {
      this.props.dispatch(actions.setPlay(false));
    }
  };

  componentDidUpdate = (props) => {
    if (props.play !== this.props.play && this.props.play) {
      this.runTimer();
    }
  };

  componentDidMount = (props) => {
    if (this.props.play) this.runTimer();
  };

  render() {
    return (
      <div className="Timer">
        <div className="Bar">
          <div
            style={{
              width: (this.props.time * 100) / this.props.timeMax + "%",
              background: this.props.time < 10000 ? "#f82830" : "#f8d848",
            }}
          >
            &nbsp;
            <span>{millisToMinutesAndSeconds(this.props.time)}</span>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, props) {
  return {
    time: state.game.time,
    timeMax: state.game.timeMax,
    play: state.game.play,
  };
}

export default connect(mapStateToProps)(Timer);
