import {
  SET_LOADING,
  SET_PLAY,
  SET_USER,
  TIME_SET,
  VIEW_SET,
  INITIALIZE_LEVEL,
  SET_LEVEL_VIEW,
  START_NEXT_LEVEL,
  GAME_OVER,
  ADD_SCORE,
  SET_RANKING,
  SET_TEMP,
  SET_SAVED_RANK,
  SET_SHOW_ERROR,
  ADD_TEN_SECONDS,
  SET_POWER_REMOVE_ACTIVE,
  SHOW_POPUP_POWERS,
  SET_NEW_POWERS,
} from "../Reducers/types.js";

import { getConfig } from "../config";

import axios from "axios";

const server = axios.create({
  headers: {
    "Content-Type": "application/json; charset=utf-8",
  },
  withCredentials: true,
});

export const setLoading = (loading) => ({
  type: SET_LOADING,
  loading,
});

export const setPlay = (play) => ({
  type: SET_PLAY,
  play,
});

export const setUser = (user) => ({
  type: SET_USER,
  user,
});

export const setTime = (time) => ({
  type: TIME_SET,
  time,
});

export const setView = (view) => ({
  type: VIEW_SET,
  view,
});

export const initFirstLevel = () => ({
  type: INITIALIZE_LEVEL,
});

export const setLvlView = () => ({
  type: SET_LEVEL_VIEW,
});

export const startNewLevel = (items) => ({
  type: START_NEXT_LEVEL,
  items,
});

export const gameOver = () => ({
  type: GAME_OVER,
});

export const addScore = (score) => ({
  type: ADD_SCORE,
  score,
});

export const setRanking = (ranking) => ({
  type: SET_RANKING,
  ranking,
});

export const setTemp = (temp) => ({
  type: SET_TEMP,
  temp,
});

export const setSavedRank = (savedRank) => ({
  type: SET_SAVED_RANK,
  savedRank,
});

export const showError = (showError) => ({
  type: SET_SHOW_ERROR,
  showError,
});

export const showPopUpPowers = (showPopUpPowers) => ({
  type: SHOW_POPUP_POWERS,
  showPopUpPowers,
});

export const addTenSeconds = () => ({
  type: ADD_TEN_SECONDS,
});

export const setNewPowers = (powers) => ({
  type: SET_NEW_POWERS,
  powers,
});

export const setPowerRemoveActive = (powerRemoveActive) => ({
  type: SET_POWER_REMOVE_ACTIVE,
  powerRemoveActive,
});

export const loadRanking = () => {
  return async (dispatch) => {
    dispatch(showError(false));
    dispatch(setLoading(true));
    await server
      .get(`${getConfig().baseUrl}/getRanking`)
      .then((response) => {
        if (response.data.status === "OK") {
          dispatch(setRanking(response.data.items));
          dispatch(showError(false));
          dispatch(setLoading(false));
        } else {
          dispatch(setLoading(false));
          dispatch(showError(true));
        }
      })
      .catch((error) => {
        dispatch(setRanking([]));
        dispatch(setLoading(false));
        dispatch(showError(true));
      });
  };
};

export const saveRecord = (rank) => {
  return async (dispatch) => {
    dispatch(showError(false));
    dispatch(setLoading(true));
    await server
      .post(`${getConfig().baseUrl}/new`, { ...rank })
      .then((response) => {
        if (response.data.status === "OK") {
          dispatch(setSavedRank(response.data.item));
          dispatch(setLoading(false));
          dispatch(showError(false));
        } else {
          dispatch(setLoading(false));
          dispatch(showError(true));
        }
      })
      .catch((error) => {
        dispatch(setLoading(true));
        dispatch(showError(true));
      });
  };
};
