import {
  SET_LOADING,
  SET_PLAY,
  SET_USER,
  TIME_SET,
  VIEW_SET,
  INITIALIZE_LEVEL,
  SET_LEVEL_VIEW,
  START_NEXT_LEVEL,
  GAME_OVER,
  ADD_SCORE,
  SET_RANKING,
  SET_TEMP,
  SET_SAVED_RANK,
  SET_SHOW_ERROR,
  ADD_TEN_SECONDS,
  SET_POWER_REMOVE_ACTIVE,
  SHOW_POPUP_POWERS,
  SET_NEW_POWERS,
} from "./types";

import { TIME } from "../Config/ConfigGame";

const tempInit = {
  FirstXY: null,
  SecondXY: null,
  touchStartX: 0,
  touchStartY: 0,
  round: 1,
  atLeastThreeInLine: false,
  items: [],
};

const init = {
  view: "Home",
  user: null,
  loading: false,
  play: false,
  timeMax: TIME,
  time: 0,
  scoreGlobal: 0,
  score: 0,
  lvl: 0,
  ranking: [],
  savedRank: null,
  showError: false,
  showPopUpPowers: null,
  powerRemoveActive: null,
  powers: { add: 1, replace: 1, remove: 1 },
  temp: { ...tempInit },
};

export default function game(state = { ...init }, action = {}) {
  switch (action.type) {
    case SET_LOADING:
      return { ...state, loading: action.loading };
    case SET_PLAY:
      return { ...state, play: action.play };
    case SET_USER:
      return { ...state, user: action.user };
    case TIME_SET:
      return { ...state, time: action.time };
    case VIEW_SET:
      return { ...state, view: action.view };
    case SET_TEMP:
      return { ...state, temp: action.temp };
    case INITIALIZE_LEVEL:
      return {
        ...state,
        timeMax: TIME,
        scoreGlobal: 0,
        score: 0,
        lvl: 0,
        powers: { add: 1, replace: 1, remove: 1 },
        showPopUpPowers: null,
        powerRemoveActive: null,
      };
    case SET_SAVED_RANK:
      return { ...state, savedRank: action.savedRank };
    case SET_SHOW_ERROR:
      return { ...state, showError: action.showError };
    case SET_NEW_POWERS:
      return { ...state, powers: action.powers };
    case ADD_TEN_SECONDS:
      return {
        ...state,
        time: state.time + 10000,
        timeMax: state.timeMax + 10000,
        showPopUpPowers: null,
        powers: { ...state.powers, add: state.powers.add - 1 },
      };
    case SET_POWER_REMOVE_ACTIVE:
      return {
        ...state,
        powerRemoveActive: action.powerRemoveActive,
        powers: { ...state.powers, remove: state.powers.remove - 1 },
      };
    case SHOW_POPUP_POWERS:
      return { ...state, showPopUpPowers: action.showPopUpPowers };
    case SET_LEVEL_VIEW:
      return {
        ...state,
        scoreGlobal: state.scoreGlobal + state.score,
        score: 0,
        timeMax: TIME,
        powerRemoveActive: null,
        showPopUpPowers: null,
        lvl: state.lvl + 1,
        view: "Level",
      };
    case START_NEXT_LEVEL:
      return {
        ...state,
        play: true,
        time: state.timeMax,
        temp: { ...state.temp, items: action.items },
      };
    case GAME_OVER:
      return {
        ...state,
        view: "GameOver",
        scoreGlobal: state.scoreGlobal + state.score,
      };
    case ADD_SCORE:
      return {
        ...state,
        score: state.score + action.score,
      };
    case SET_RANKING:
      return {
        ...state,
        ranking: action.ranking,
      };
    default:
      return state;
  }
}
