import React, { Component } from "react";
import { connect } from "react-redux";

import HomeView from "./Components/View/HomeView";
import RankingView from "./Components/View/RankingView";
import LevelView from "./Components/View/LevelView";
import GameView from "./Components/View/GameView";
import GameOverView from "./Components/View/GameOverView";
import SaveRecordView from "./Components/View/SaveRecordView";
import Loading from "./Components/Loading";
import LoginGameOuter from "./Components/LoginGameOuter";

import "./App.css";

class App extends Component {
  render() {
    return (
      <LoginGameOuter>
        {this.props.loading && (
          <div className="GameFrame" style={{ zIndex: 22 }}>
            <Loading />
          </div>
        )}
        <div style={{ opacity: this.props.loading ? 0 : 1 }}>
          {this.props.view === "Home" && <HomeView />}
          {this.props.view === "Ranking" && <RankingView />}
          {this.props.view === "Level" && <LevelView />}
          {this.props.view === "Game" && <GameView />}
          {this.props.view === "GameOver" && <GameOverView />}
          {this.props.view === "SaveRecord" && <SaveRecordView />}
        </div>
      </LoginGameOuter>
    );
  }
}

function mapStateToProps(state, props) {
  return {
    loading: state.game.loading,
    view: state.game.view,
  };
}

export default connect(mapStateToProps)(App);
