import getRandomInt from "../Utils/getRandomInt";
import getRandomNewId from "./getRandomNewId";
import levels from "../Config/levels";

const getRandomColor = (lvl, notThisColor = -1) => {
  let index = getRandomInt(0, levels[lvl - 1].length);
  let color = levels[lvl - 1][index];

  if (index !== notThisColor) return { color, index };
  else return getRandomColor(lvl, notThisColor);
};

const getSpecificColor = (lvl, index) => {
  let color = levels[lvl - 1][index];
  return { color, index };
};

const getNewElement = (existingElements, lvl, scale, powerRemoveActive, replacementColor, from) => {
  let dataColor = getRandomColor(lvl);

  switch (from) {
    case "DELETE":
      dataColor = getRandomColor(lvl, powerRemoveActive);
      break;
    case "REPLACE":
      dataColor = getSpecificColor(lvl, replacementColor);
      break;
    default:
      break;
  }

  return {
    id: getRandomNewId(1, 1000000000, existingElements),
    color: dataColor.color,
    indexColor: dataColor.index,
    show: true,
    translateX: 0,
    translateY: 0,
    scale,
    animated: true,
    isTemporary: false,
  };
};

export default getNewElement;
