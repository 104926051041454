import getNewElement from "./getNewElement";
// import { MAX_X, MAX_Y } from "../Config/ConfigGame";
import points from "../Config/points";

const refillRowWithPoints = (existingElements, lvl, elements, x, y, round) => {
  round = round > 4 ? 4 : round;
  if (x > 0) {
    if (elements[x][y].show === false) {
      let pivot = getNewElement(existingElements, lvl, 0.6);
      pivot.color = points[round];
      pivot.show = false;
      pivot.isTemporary = true;
      elements[x][y] = pivot;
      refillRowWithPoints(existingElements, lvl, elements, x - 1, y, round);
    } else refillRowWithPoints(existingElements, lvl, elements, x - 1, y, round);
  } else {
    if (elements[x][y].show === false) {
      let pivot2 = getNewElement(existingElements, lvl, 0.6);
      pivot2.color = points[round];
      pivot2.show = false;
      pivot2.isTemporary = true;
      elements[x][y] = pivot2;
    }
  }

  return elements;
};

export default refillRowWithPoints;
