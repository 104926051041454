import React, { Component } from "react";
import { connect } from "react-redux";

import green from "../Assets/green.png";
import blue from "../Assets/blue.png";
import red from "../Assets/red.png";
import grey from "../Assets/grey.png";

class CandyPower extends Component {
  render() {
    return (
      <div onClick={this.props.handleOnClick} style={{ position: "relative", width: "100px", cursor: "pointer", userSelect: "none" }}>
        {this.props.number > 0 && (
          <span style={{ position: "absolute", textAlign: "right", right: "20px", top: "10px" }}>{this.props.number}</span>
        )}
        <div style={{ position: "absolute", zIndex: 10, left: "43px", top: "40px" }}>
          {this.props.type === "ADD" && <span>A</span>}
          {this.props.type === "REPLACE" && <span>R</span>}
          {this.props.type === "REMOVE" && <span>D</span>}
        </div>
        <img
          alt={this.props.type}
          style={{ position: "absolute", width: "100px", height: "100px" }}
          src={this.props.number === 0 ? grey : this.props.type === "ADD" ? green : this.props.type === "REPLACE" ? blue : red}
        />
      </div>
    );
  }
}

function mapStateToProps(state, props) {
  return {};
}

export default connect(mapStateToProps)(CandyPower);
