import React, { Component } from "react";
import { connect } from "react-redux";

import getNewElement from "./../Functions/getNewElement";
import PopUp from "./PopUp";

import * as actions from "../Actions/gameActions";

import levels from "../Config/levels";

class PopUpRemove extends Component {
  state = { remove: null };

  selectFirst = (remove) => this.setState({ remove });

  ok = () => {
    if (this.state.remove !== null && this.props.powers.remove > 0) {
      this.props.dispatch(actions.setPowerRemoveActive(this.state.remove));

      let newItems = [];
      let existingElements = [];

      this.props.temp.items.forEach((rowsElementsOld) =>
        rowsElementsOld.forEach((element) => {
          existingElements.push(element.id);
        })
      );

      let row = [];
      this.props.temp.items.forEach((rowsOld) => {
        row = [];
        rowsOld.forEach((item) => {
          if (item.indexColor === this.state.remove) {
            row.push(getNewElement(existingElements, this.props.lvl, 1, this.state.remove, -1, "DELETE"));
          } else row.push(item);
        });
        newItems.push(row);
      });

      this.props.dispatch(
        actions.setTemp({
          ...this.props.temp,
          items: newItems,
        })
      );

      this.props.dispatch(actions.showPopUpPowers(null));
    }
  };

  cancel = () => {
    this.props.dispatch(actions.showPopUpPowers(null));
  };

  render() {
    return (
      <PopUp
        color="#ff0000"
        disabledColor="#ff000066"
        title="power REMOVE"
        description="remove a candy for this lvl"
        okDisabled={this.state.remove == null}
        ok={this.ok}
        cancel={this.cancel}
      >
        <ul style={{ display: "flex", listStyle: "none", marginTop: 0, justifyContent: "space-between", paddingLeft: 0 }}>
          {levels[this.props.lvl - 1].map((item, index) => {
            return (
              <li
                onClick={() => this.selectFirst(index)}
                key={index}
                style={{
                  backgroundImage: "url(" + item + ")",
                  backgroundSize: "100%",
                  width: "10px",
                  height: "10px",
                  padding: "10px",
                  filter: index === this.state.remove ? `grayscale(100%)` : "",
                }}
              ></li>
            );
          })}
        </ul>
      </PopUp>
    );
  }
}

function mapStateToProps(state, props) {
  return {
    powers: state.game.powers,
    lvl: state.game.lvl,
    temp: state.game.temp,
  };
}

export default connect(mapStateToProps)(PopUpRemove);
