import React, { Component } from "react";
import { connect } from "react-redux";
import { isMobile, isBrowser } from "react-device-detect";

import Timer from "../Timer";
import PowersPanel from "../PowersPanel";
import PopUpAdd from "../PopUpAdd";
import PopUpReplace from "../PopUpReplace";
import PopUpRemove from "../PopUpRemove";
import { fadeOut } from "react-animations";
import { StyleSheet, css } from "aphrodite";
import * as actions from "../../Actions/gameActions";

import { POINT_PER_LVL, MAX_X, MAX_Y } from "../../Config/ConfigGame";

import { refillSpacesWithPoints, refillSpaces, loadItems } from "../LoadBoard";
import "./Styles/GameView.css";

class GameView extends Component {
  state = { item: null, first: null };
  componentDidMount = () => {
    this.props.dispatch(actions.startNewLevel(loadItems()));

    setTimeout(() => this.checkColorsInAllBoard(true, 0, 0, 0, 0, 0, 0, 1), 400);
  };

  componentDidUpdate(props) {
    if (this.props.play !== props.play && !this.props.play) {
      if (this.props.score < this.props.lvl * POINT_PER_LVL) this.props.dispatch(actions.gameOver());
      else if (this.props.score >= this.props.lvl * POINT_PER_LVL) {
        this.props.dispatch(actions.setLvlView());
      }
    }
    if (this.props.powers !== props.powers) setTimeout(() => this.checkColorsInAllBoard(true, 0, 0, 0, 0, 0, 0, 1), 400);
  }

  getXandY = (e) => {
    let x, y;
    if (e.type === "touchstart" || e.type === "touchmove" || e.type === "touchend" || e.type === "touchcancel") {
      var evt = typeof e.originalEvent === "undefined" ? e : e.originalEvent;
      var touch = evt.touches[0] || evt.changedTouches[0];
      x = touch.pageX;
      y = touch.pageY;
    } else if (
      e.type === "mousedown" ||
      e.type === "mouseup" ||
      e.type === "mousemove" ||
      e.type === "mouseover" ||
      e.type === "mouseout" ||
      e.type === "mouseenter" ||
      e.type === "mouseleave"
    ) {
      x = e.clientX;
      y = e.clientY;
    }

    return [x, y];
  };

  handleTouchend = (e) => {
    if (isMobile) {
      let x = this.getXandY(e)[0];
      let y = this.getXandY(e)[1];

      if (this.props.temp.FirstXY != null) {
        if (Math.abs(this.props.temp.touchStartX - x) > Math.abs(this.props.temp.touchStartY - y)) {
          if (this.props.temp.touchStartX - x > 0) {
            if (this.props.temp.FirstXY[1] - 1 >= 0)
              this.swapTwoItems(
                true,
                [this.props.temp.FirstXY[0], this.props.temp.FirstXY[1]],
                [this.props.temp.FirstXY[0], this.props.temp.FirstXY[1] - 1],
                0,
                -75,
                0,
                75
              );
            else this.props.dispatch(actions.setTemp({ ...this.props.temp, FirstXY: null }));
          } else {
            if (this.props.temp.FirstXY[1] + 1 < MAX_Y)
              this.swapTwoItems(
                true,
                [this.props.temp.FirstXY[0], this.props.temp.FirstXY[1]],
                [this.props.temp.FirstXY[0], this.props.temp.FirstXY[1] + 1],
                0,
                75,
                0,
                -75
              );
            else this.props.dispatch(actions.setTemp({ ...this.props.temp, FirstXY: null }));
          }
        } else {
          if (this.props.temp.touchStartY - y > 0) {
            if (this.props.temp.FirstXY[0] - 1 >= 0)
              this.swapTwoItems(
                true,
                [this.props.temp.FirstXY[0], this.props.temp.FirstXY[1]],
                [this.props.temp.FirstXY[0] - 1, this.props.temp.FirstXY[1]],
                -75,
                0,
                75,
                0
              );
            else this.props.dispatch(actions.setTemp({ ...this.props.temp, FirstXY: null }));
          } else {
            if (this.props.temp.FirstXY[0] + 1 < MAX_Y)
              this.swapTwoItems(
                true,
                [this.props.temp.FirstXY[0], this.props.temp.FirstXY[1]],
                [this.props.temp.FirstXY[0] + 1, this.props.temp.FirstXY[1]],
                75,
                0,
                -75,
                0
              );
            else this.props.dispatch(actions.setTemp({ ...this.props.temp, FirstXY: null }));
          }
        }
      }
    }
  };

  swapTwoItems = (realMovement, xy_First, xy_Second, x1, y1, x2, y2) => {
    let pivot, items;
    items = this.props.temp.items;
    items[xy_First[0]][xy_First[1]].translateX = x1;
    items[xy_First[0]][xy_First[1]].translateY = y1;
    items[xy_Second[0]][xy_Second[1]].translateX = x2;
    items[xy_Second[0]][xy_Second[1]].translateY = y2;

    this.props.dispatch(actions.setTemp({ ...this.props.temp, items: items }));

    setTimeout(() => {
      pivot = this.cleanTranslates(Object.assign({}, items[xy_First[0]][xy_First[1]]));
      items[xy_First[0]][xy_First[1]] = this.cleanTranslates(items[xy_Second[0]][xy_Second[1]]);
      items[xy_Second[0]][xy_Second[1]] = pivot;
      items[xy_First[0]][xy_First[1]].animated = false;
      items[xy_Second[0]][xy_Second[1]].animated = false;

      this.props.dispatch(
        actions.setTemp({
          ...this.props.temp,
          items: items,
          FirstXY: null,
          SecondXY: null,
        })
      );

      setTimeout(() => {
        items[xy_First[0]][xy_First[1]].animated = true;
        items[xy_Second[0]][xy_Second[1]].animated = true;
        this.props.dispatch(
          actions.setTemp({
            ...this.props.temp,
            items: items,
          })
        );

        if (realMovement) this.checkColorsInAllBoard(false, xy_Second, xy_First, x2, y2, x1, y1, 1);
      }, 100);
    }, 400);
  };

  cleanTranslates = (item) => {
    item.translateY = 0;
    item.translateX = 0;
    return item;
  };

  checkColorsInAllBoard = (nonPreviosMovement, xy_Second, xy_First, x2, y2, x1, y1, round) => {
    this.props.dispatch(
      actions.setTemp({
        ...this.props.temp,
        round: round > 4 ? 4 : round,
        atLeastThreeInLine: false,
      })
    );

    let itemsDeleteTotal = [];
    for (let indexX = 0; indexX < MAX_X; indexX++) {
      for (let indexY = 0; indexY < MAX_Y; indexY++) {
        let itemsDelete = [];
        this.checkColors(this.props.temp.items, itemsDelete, indexX, indexY);
        if (this.props.temp.atLeastThreeInLine) {
          this.props.dispatch(
            actions.setTemp({
              ...this.props.temp,
              atLeastThreeInLine: false,
            })
          );
          itemsDeleteTotal = itemsDeleteTotal.concat(itemsDelete);
          itemsDeleteTotal = itemsDeleteTotal.filter(
            // eslint-disable-next-line
            (item, index) => itemsDeleteTotal.indexOf(item) === index
          );
        }
      }
    }

    if (itemsDeleteTotal.length === 0 && !nonPreviosMovement) this.swapTwoItems(false, xy_Second, xy_First, x2, y2, x1, y1);

    if (itemsDeleteTotal.length > 0) {
      this.props.dispatch(actions.addScore(itemsDeleteTotal.length * 10 * round));

      this.props.dispatch(
        actions.setTemp({
          ...this.props.temp,
          items: this.disappearElements(this.props.temp.items, itemsDeleteTotal),
          atLeastThreeInLine: false,
        })
      );

      setTimeout(() => {
        this.props.dispatch(
          actions.setTemp({
            ...this.props.temp,
            items: refillSpacesWithPoints(this.props.lvl, this.props.temp.items, round),
          })
        );

        this.animationNewBalls();
        setTimeout(() => {
          this.props.dispatch(
            actions.setTemp({
              ...this.props.temp,
              items: refillSpaces(this.props.lvl, this.props.temp.items, this.props.powerRemoveActive),
            })
          );

          this.animationNewBalls();
          setTimeout(() => {
            this.checkColorsInAllBoard(true, 0, 0, 0, 0, 0, 0, round + 1);
          }, 500);
        }, 500);
      }, 300);
    }
  };

  checkColors = (elements, elementsDelete, x, y) => {
    let item = elements[x][y];

    if (item !== null && item.show) {
      if (
        !this.props.temp.atLeastThreeInLine &&
        x < 3 &&
        x > 0 &&
        elements[x - 1][y].color === elements[x][y].color &&
        elements[x][y].color === elements[x + 1][y].color
      )
        this.props.dispatch(
          actions.setTemp({
            ...this.props.temp,
            atLeastThreeInLine: true,
          })
        );

      if (
        !this.props.temp.atLeastThreeInLine &&
        y < 3 &&
        y > 0 &&
        elements[x][y - 1].color === elements[x][y].color &&
        elements[x][y].color === elements[x][y + 1].color
      )
        this.props.dispatch(
          actions.setTemp({
            ...this.props.temp,
            atLeastThreeInLine: true,
          })
        );

      elementsDelete.push(item.id);

      if (y < 3 && item.color === elements[x][y + 1].color && !elementsDelete.includes(elements[x][y + 1].id))
        elementsDelete.concat(this.checkColors(elements, elementsDelete, x, y + 1));

      if (x < 3 && item.color === elements[x + 1][y].color && !elementsDelete.includes(elements[x + 1][y].id))
        elementsDelete.concat(this.checkColors(elements, elementsDelete, x + 1, y));

      if (x > 0 && item.color === elements[x - 1][y].color && !elementsDelete.includes(elements[x - 1][y].id))
        elementsDelete.concat(this.checkColors(elements, elementsDelete, x - 1, y));

      if (y > 0 && item.color === elements[x][y - 1].color && !elementsDelete.includes(elements[x][y - 1].id))
        elementsDelete.concat(this.checkColors(elements, elementsDelete, x, y - 1));
    }

    return elementsDelete;
  };

  disappearElements = (elements, elementsDelete) => {
    elements.forEach((row) => {
      row.forEach((item) => {
        if (elementsDelete.includes(item.id)) item.show = false;
      });
    });

    return elements;
  };

  animationNewBalls = () => {
    for (let indexX = 0; indexX < MAX_X; indexX++) for (let indexY = 0; indexY < MAX_Y; indexY++) this.lunchEffect(indexX, indexY);
  };

  lunchEffect = (x, y) => {
    setTimeout(() => {
      let newItems = this.props.temp.items;
      if (newItems[x][y].scale < 1) {
        newItems[x][y].scale = newItems[x][y].scale + 0.1;
        this.props.dispatch(
          actions.setTemp({
            ...this.props.temp,
            items: newItems,
          })
        );
        this.lunchEffect(x, y);
      }
    }, 50);
  };

  handleTouchstart = (e, indexX, indexY) => {
    if (isMobile) {
      if (this.props.temp.FirstXY === null) {
        let x = this.getXandY(e)[0];
        let y = this.getXandY(e)[1];

        this.props.dispatch(
          actions.setTemp({
            ...this.props.temp,
            touchStartX: x,
            touchStartY: y,
            FirstXY: [indexX, indexY],
          })
        );
      }
    }
  };

  handleOnClick = (item, indexX, indexY) => {
    if (isBrowser) {
      if (this.state.first === null) {
        this.setState({ item, first: { indexX, indexY } });
      } else {
        let justOneMove = Math.abs(this.state.first.indexX - indexX) + Math.abs(this.state.first.indexY - indexY);
        if (
          justOneMove === 1 &&
          (this.state.first.indexX === indexX + 1 || this.state.first.indexX === indexX - 1 || this.state.first.indexX === indexX) &&
          (this.state.first.indexY === indexY + 1 || this.state.first.indexY === indexY - 1 || this.state.first.indexY === indexY)
        ) {
          this.swapTwoItems(
            true,
            [this.state.first.indexX, this.state.first.indexY],
            [indexX, indexY],
            (indexX - this.state.first.indexX) * 75,
            (indexY - this.state.first.indexY) * 75,
            (this.state.first.indexX - indexX) * 75,
            (this.state.first.indexY - indexY) * 75
          );
          this.setState({ item: null, first: null });
        } else {
          this.setState({ item, first: { indexX, indexY } });
        }
      }
    }
  };

  render() {
    return (
      <div className="GameView">
        <div className="GameViewInfo">
          <div className="GameViewTitle">level {this.props.lvl}</div>
          <span className="GameViewPoints">points: {this.props.score}</span>
          <Timer />
        </div>
        <div className="GameViewBoard">
          {this.props.showPopUpPowers === "ADD" && <PopUpAdd />}
          {this.props.showPopUpPowers === "REPLACE" && <PopUpReplace />}
          {this.props.showPopUpPowers === "REMOVE" && <PopUpRemove />}
          <div className="GameViewCandies">
            {this.props.temp.items.map((row, indexX) => {
              return (
                <div key={indexX}>
                  {row.map((item, indexY) => {
                    let isSelected = false;
                    let firstItem =
                      this.props.temp.FirstXY !== null
                        ? this.props.temp.items[this.props.temp.FirstXY[0]][this.props.temp.FirstXY[1]]
                        : null;

                    let secondItem =
                      this.props.temp.SecondXY !== null
                        ? this.props.temp.items[this.props.temp.SecondXY[0]][this.props.temp.SecondXY[1]]
                        : null;

                    if (
                      (firstItem != null && item.id === firstItem.id) ||
                      (this.state.first != null && this.state.first.indexX === indexX && this.state.first.indexY === indexY)
                    )
                      isSelected = true;
                    if (secondItem != null && item.id === secondItem.id) isSelected = true;

                    let style = {
                      backgroundImage: "url(" + item.color + ")",
                      backgroundSize: "100%",
                      transform: `translate3d(${item.translateY}px, ${item.translateX}px, 0) scale(${item.scale})`,
                    };

                    return (
                      <div
                        key={item.id}
                        onTouchStart={(event) => this.handleTouchstart(event, indexX, indexY)}
                        onTouchEnd={(event) => this.handleTouchend(event)}
                        onClick={(event) => this.handleOnClick(item, indexX, indexY)}
                        className={`GameBall ${item.show || item.isTemporary ? "" : css(styles.bounceOut)}  ${
                          item.animated ? "animation" : ""
                        }`}
                        style={{ ...style, border: `1px solid ${isSelected ? "white" : "black"}` }}
                      >
                        {isSelected && <span className="GameViewFirstItem" />}
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
          <PowersPanel />
        </div>
      </div>
    );
  }
}

const styles = StyleSheet.create({
  bounceOut: {
    opacity: 0,
    animationName: fadeOut,
    animationDuration: "0.5s",
  },
});

function mapStateToProps(state, props) {
  return {
    view: state.game.view,
    play: state.game.play,
    lvl: state.game.lvl,
    score: state.game.score,
    temp: state.game.temp,
    showPopUpPowers: state.game.showPopUpPowers,
    powerRemoveActive: state.game.powerRemoveActive,
    powers: state.game.powers,
  };
}

export default connect(mapStateToProps)(GameView);
