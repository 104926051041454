import React from "react";
import ReactDOM from "react-dom";
import { createStore, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import rootReducers from "./Reducers/rootReducers";
import { composeWithDevTools } from "redux-devtools-extension";

import history from "./Utils/history";
import { Auth0Provider } from "@auth0/auth0-react";
import { getConfig } from "./config";

const onRedirectCallback = appState => {
  history.push(appState && appState.returnTo ? appState.returnTo : window.location.pathname);
};

const store = createStore(rootReducers, composeWithDevTools(applyMiddleware(thunk)));

const config = getConfig();

const providerConfig = {
  domain: config.domain,
  clientId: config.clientId,
  ...(config.audience ? { audience: config.audience } : null),
  redirectUri: window.location.origin,
  onRedirectCallback,
};

ReactDOM.render(
  <Auth0Provider {...providerConfig}>
    <Provider store={store}>
      <App />
    </Provider>
  </Auth0Provider>,
  document.getElementById("root")
);

reportWebVitals();
