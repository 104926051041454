import React, { Component } from "react";
import { connect } from "react-redux";

import NoConnection from "../NoConnection";

import * as actions from "../../Actions/gameActions";

import "./Styles/SaveRecordView.css";

class SaveRecordView extends Component {
  componentDidMount() {
    setTimeout(() => {
      if (this.props.user) {
        this.props.dispatch(
          actions.saveRecord({
            name: this.props.user.name,
            score: this.props.score,
            lvl: this.props.lvl,
          })
        );
      } else {
        this.props.dispatch(actions.setView("GameOver"));
      }
    }, 100);
  }

  accept = () => {
    this.props.dispatch(actions.setSavedRank(null));
    this.props.dispatch(actions.setView("Ranking"));
  };

  tryAgain = () => {
    this.props.dispatch(actions.setLoading(true));
    if (this.props.user) {
      this.props.dispatch(
        actions.saveRecord({
          name: this.props.user.name,
          score: this.props.score,
          lvl: this.props.lvl,
        })
      );
    } else {
      this.props.dispatch(actions.setView("GameOver"));
    }
  };

  render() {
    return (
      <React.Fragment>
        {this.props.user && (
          <React.Fragment>
            {this.props.showError && <NoConnection tryAgain={this.tryAgain} />}
            <div className="GameFrame">
              <div key={this.props.savedRank} className="SaveRecordViewTitle">
                {this.props.savedRank != null && this.props.savedRank.position != null && (
                  <React.Fragment>
                    <div>
                      <span style={{ color: "red" }}>C</span>
                      <span style={{ color: "blue" }}>a</span>
                      <span style={{ color: "yellow" }}>n</span>
                      <span style={{ color: "white" }}>d</span>
                      <span style={{ color: "green" }}>y</span>
                      <span style={{ color: "grey" }}>D</span>
                      <span style={{ color: "purple" }}>e</span>
                      <span style={{ color: "brown" }}>v</span>
                    </div>
                    <br />
                    <div className="SaveRecordViewCongratulation">congratulation</div>
                    <br />
                    <div>you are the number {this.props.savedRank.position}</div>
                  </React.Fragment>
                )}
              </div>
              {this.props.savedRank != null && this.props.savedRank.position == null && (
                <div className="SaveRecordViewTitle">record saved</div>
              )}
              <button onClick={() => this.accept()} className="GameBtn">
                accept
              </button>
            </div>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

function mapStateToProps(state, props) {
  return {
    user: state.game.user,
    lvl: state.game.lvl,
    score: state.game.scoreGlobal,
    savedRank: state.game.savedRank,
    showError: state.game.showError,
  };
}

export default connect(mapStateToProps)(SaveRecordView);
