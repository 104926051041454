import angularjs from "../Assets/logos/angularjs.png";
import bootstrap from "../Assets/logos/bootstrap.png";
import css3 from "../Assets/logos/css3.png";
import html5 from "../Assets/logos/html5.png";
import java from "../Assets/logos/java.png";
import js from "../Assets/logos/js.png";
import mysql from "../Assets/logos/mysql.png";
import net from "../Assets/logos/net.png";
import reactjs from "../Assets/logos/reactjs.png";

const lvl1 = [bootstrap, css3, html5, js];
const lvl2 = [bootstrap, css3, html5, js, angularjs];
const lvl3 = [bootstrap, css3, html5, js, angularjs, html5];
const lvl4 = [bootstrap, css3, html5, js, angularjs, html5, reactjs];
const lvl5 = [bootstrap, css3, html5, js, angularjs, html5, reactjs, java];
const lvl6 = [bootstrap, css3, html5, js, angularjs, html5, reactjs, java, mysql];
const lvl7 = [bootstrap, css3, html5, js, angularjs, html5, reactjs, java, mysql, net];

const levels = [lvl1, lvl2, lvl3, lvl4, lvl5, lvl6, lvl7];

export default levels;
