import { useAuth0 } from "@auth0/auth0-react";
import { connect } from "react-redux";
import Loading from "./Loading";
import * as actions from "../Actions/gameActions";
import "./Styles/LoginGameOuter.css";

const LoginGameOuter = (props) => {
  const { isLoading, isAuthenticated, user } = useAuth0();

  if (isLoading) {
    return (
      <div className="LoginGameOuter">
        <div className="GameFrame">
          <Loading />
        </div>
      </div>
    );
  }

  if (isAuthenticated) {
    props.dispatch(actions.setUser(user));
  }

  return <div className="LoginGameOuter">{props.children}</div>;
};

function mapStateToProps(state, props) {
  return {
    loading: state.game.loading,
  };
}

export default connect(mapStateToProps)(LoginGameOuter);
