import React, { Component } from "react";

import "./Styles/PopUp.css";

class PopUp extends Component {
  render() {
    return (
      <div className="PopUp">
        <div style={{ padding: "15px" }}>
          <div className="PopUpTitle" style={{ color: this.props.color }}>
            {this.props.title}
          </div>
          <div className="PopUpDescription">{this.props.description}</div>
          <div className="PopUpBody">
            {this.props.children}
            {this.props.ok && (
              <button
                disabled={this.props.okDisabled}
                onClick={this.props.ok}
                className="PopUpBtn"
                style={{ background: this.props.okDisabled ? this.props.disabledColor : this.props.color }}
              >
                accept
              </button>
            )}
            <button onClick={this.props.cancel} className="PopUpBtn">
              cancel
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default PopUp;
