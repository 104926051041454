import refillRowWithPoints from "./refillRowWithPoints";

const refillSpacesWithPoints = (lvl, elements, round) => {
  let existingElements = [];
  elements.forEach(element => existingElements.push(element.id));
  for (let index = 3; index >= 0; index--) elements = refillRowWithPoints(existingElements, lvl, elements, 3, index, round);
  return elements;
};

export default refillSpacesWithPoints;
