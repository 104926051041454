import React, { Component } from "react";
import { connect } from "react-redux";

import * as actions from "../../Actions/gameActions";

class HomeView extends Component {
  startGame = () => {
    this.props.dispatch(actions.initFirstLevel());
    this.props.dispatch(actions.setLvlView());
  };

  rankingOpen = () => this.props.dispatch(actions.setView("Ranking"));

  render() {
    return (
      <div className="GameFrame">
        <button onClick={() => this.startGame()} className="GameBtn" style={{ margin: 0 }}>
          play
        </button>
        <button onClick={() => this.rankingOpen()} className="GameBtn">
          best player
        </button>
      </div>
    );
  }
}

function mapStateToProps(state, props) {
  return {};
}

export default connect(mapStateToProps)(HomeView);
