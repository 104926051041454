import getNewElement from "./getNewElement";
import { MAX_X, MAX_Y } from "../Config/ConfigGame";

const loadItems = () => {
  let items = [];
  while (items.length < MAX_X) {
    let row = [];
    while (row.length < MAX_Y) row.push(getNewElement([], 1, 1));
    items.push(row);
  }
  return items;
};

export default loadItems;
