export const SET_LOADING = "SET_LOADING";
export const SET_PLAY = "SET_PLAY";
export const SET_USER = "SET_USER";
export const TIME_SET = "TIME_SET";
export const VIEW_SET = "VIEW_SET";
export const INITIALIZE_LEVEL = "INITIALIZE_LEVEL";
export const SET_LEVEL_VIEW = "SET_LEVEL_VIEW";
export const START_NEXT_LEVEL = "START_NEXT_LEVEL";
export const GAME_OVER = "GAME_OVER";
export const ADD_SCORE = "ADD_SCORE";
export const SET_RANKING = "SET_RANKING";
export const SET_TEMP = "SET_TEMP";
export const SET_SAVED_RANK = "SET_SAVED_RANK";
export const SET_SHOW_ERROR = "SET_SHOW_ERROR";
export const ADD_TEN_SECONDS = "ADD_TEN_SECONDS";
export const SET_POWER_REMOVE_ACTIVE = "SET_POWER_REMOVE_ACTIVE";
export const SHOW_POPUP_POWERS = "SHOW_POPUP_POWERS";
export const SET_NEW_POWERS = "SET_NEW_POWERS";
