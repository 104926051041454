import React, { Component } from "react";
import { connect } from "react-redux";

import getNewElement from "./../Functions/getNewElement";
import PopUp from "./PopUp";

import * as actions from "../Actions/gameActions";

import levels from "../Config/levels";

class PopUpReplace extends Component {
  state = { first: null, second: null };

  selectFirst = (index) => this.setState({ ...this.state, first: index });

  selectSecond = (index) => this.setState({ ...this.state, second: index });

  ok = () => {
    if (this.props.powers.replace > 0 && this.state.first != null && this.state.second != null) {
      let newItems = [];
      let existingElements = [];

      this.props.dispatch(actions.setNewPowers({ ...this.props.powers, replace: this.props.powers.replace - 1 }));

      this.props.temp.items.forEach((rowsElementsOld) =>
        rowsElementsOld.forEach((element) => {
          existingElements.push(element.id);
        })
      );

      let row = [];
      this.props.temp.items.forEach((rowsOld) => {
        row = [];
        rowsOld.forEach((item) => {
          if (item.indexColor === this.state.first) {
            let newElement = getNewElement(existingElements, this.props.lvl, 1, null, this.state.second, "REPLACE");
            row.push(newElement);
          } else row.push(item);
        });
        newItems.push(row);
      });

      this.props.dispatch(
        actions.setTemp({
          ...this.props.temp,
          items: newItems,
        })
      );

      this.props.dispatch(actions.showPopUpPowers(false));
    }
  };

  cancel = () => this.props.dispatch(actions.showPopUpPowers(null));

  render() {
    return (
      <PopUp
        color="#0000ff"
        disabledColor="#0000ff66"
        title="power REPLACE"
        description="replace a candy for other for this lvl"
        okDisabled={this.state.first == null || this.state.second == null}
        ok={this.ok}
        cancel={this.cancel}
      >
        <div>
          <ul style={{ display: "flex", listStyle: "none", marginTop: 0, justifyContent: "space-between", paddingLeft: 0 }}>
            {levels[this.props.lvl - 1].map((item, index) => {
              return (
                <li
                  onClick={() => (index !== this.state.second ? this.selectFirst(index) : "")}
                  key={index}
                  style={{
                    backgroundImage: "url(" + item + ")",
                    backgroundSize: "100%",
                    width: "10px",
                    height: "10px",
                    padding: "10px",
                    border: `1px solid ${index === this.state.first ? "#444" : "white"}`,
                    filter: index === this.state.second ? `grayscale(100%)` : "",
                  }}
                ></li>
              );
            })}
          </ul>
        </div>
        <div>
          <ul style={{ display: "flex", listStyle: "none", marginTop: 0, justifyContent: "space-between", paddingLeft: 0 }}>
            {levels[this.props.lvl - 1].map((item, index) => {
              return (
                <li
                  onClick={() => (index !== this.state.first ? this.selectSecond(index) : "")}
                  key={index}
                  style={{
                    backgroundImage: "url(" + item + ")",
                    backgroundSize: "100%",
                    width: "10px",
                    height: "10px",
                    padding: "10px",
                    border: `1px solid ${index === this.state.second ? "#444" : "white"}`,
                    filter: index === this.state.first ? `grayscale(100%)` : "",
                  }}
                ></li>
              );
            })}
          </ul>
        </div>
      </PopUp>
    );
  }
}

function mapStateToProps(state, props) {
  return {
    powers: state.game.powers,
    lvl: state.game.lvl,
    temp: state.game.temp,
  };
}

export default connect(mapStateToProps)(PopUpReplace);
