const getColor = number => {
  switch (number) {
    case 0:
      return "red";
    case 1:
      return "pink";
    case 2:
      return "yellow";
    case 3:
      return "yellow";
    case 4:
      return "green";
    case 5:
      return "green";
    case 6:
      return "aqua";
    case 7:
      return "aqua";
    default:
      return "white";
  }
};

export default getColor;
