import React, { Component } from "react";
import { connect } from "react-redux";

import NoConnection from "../NoConnection";

import * as actions from "../../Actions/gameActions";

import getColor from "../../Functions/getColor";

import "./Styles/RankingView.css";

class RankingView extends Component {
  componentDidMount() {
    this.props.dispatch(actions.loadRanking());
  }

  rankingClose = () => this.props.dispatch(actions.setView("Home"));

  tryAgain = () => this.props.dispatch(actions.loadRanking());

  render() {
    return (
      <React.Fragment>
        {this.props.showError && <NoConnection tryAgain={this.tryAgain} />}

        <div className="RankingView">
          <div className="RankingViewTitle">
            {`— best 10 —`}
            <span onClick={this.rankingClose} className="RankingViewClose">
              x
            </span>
          </div>
          <table className="RankingViewTable">
            <tbody>
              <tr className="GameTableHead">
                <td className="short">rank</td>
                <td className="medium">score</td>
                <td className="short">lvl</td>
                <td>name</td>
              </tr>
              {this.props.best.map((item, index) => {
                return (
                  <tr key={index} className={getColor(index)}>
                    <td className="short">{`no.${index + 1}`}</td>
                    <td className="medium">{item.score}</td>
                    <td className="short text-center">{item.lvl}</td>
                    <td>{item.name}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state, props) {
  return {
    best: state.game.ranking,
    showError: state.game.showError,
  };
}

export default connect(mapStateToProps)(RankingView);
