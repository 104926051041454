import React, { Component } from "react";
import { connect } from "react-redux";

import PopUp from "./PopUp";

import * as actions from "../Actions/gameActions";

class PopUpAdd extends Component {
  ok = () => {
    if (this.props.powers.add > 0) this.props.dispatch(actions.addTenSeconds());
  };

  cancel = () => {
    this.props.dispatch(actions.showPopUpPowers(null));
  };

  render() {
    return (
      <PopUp
        color="#008000"
        disabledColor="#00800066"
        title="power ADD"
        description="add 10 seconds in this lvl"
        ok={this.ok}
        cancel={this.cancel}
      />
    );
  }
}

function mapStateToProps(state, props) {
  return {
    powers: state.game.powers,
  };
}

export default connect(mapStateToProps)(PopUpAdd);
