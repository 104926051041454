import React, { Component } from "react";

import useRecursiveTimeout from "../Functions/useRecursiveTimeout";

import "./Styles/Loading.css";

class LoadingContainer extends Component {
  state = { point: 0, text: "" };

  addPoint = () => {
    this.state.point === 3
      ? this.setState({ point: 0, text: "" })
      : this.setState({
          point: this.state.point + 1,
          text: this.state.text + ".",
        });
  };

  render() {
    return <Loading addPoint={this.addPoint} text={this.state.text} />;
  }
}

const Loading = (props) => {
  useRecursiveTimeout(() => props.addPoint(), 500);
  return <div className="Loading">loading{props.text}</div>;
};

export default LoadingContainer;
