import React, { Component } from "react";

import "./Styles/NoConnection.css";

class NoConnection extends Component {
  render() {
    return (
      <div className="NoConnection">
        <div className="NoConnectionTitle">error no connection</div>
        <div className="NoConnectionBody">
          <button className="GameBtn" onClick={this.props.tryAgain}>
            try again
          </button>
        </div>
      </div>
    );
  }
}

export default NoConnection;
