import React, { Component } from "react";
import { connect } from "react-redux";

import ButtonSaveRecord from "../ButtonSaveRecord";

import { POINT_PER_LVL } from "../../Config/ConfigGame";

import * as actions from "../../Actions/gameActions";

import "./Styles/GameOverView.css";

class GameOverView extends Component {
  backHome = () => this.props.dispatch(actions.setView("Home"));

  startGame = () => {
    this.props.dispatch(actions.initFirstLevel());
    this.props.dispatch(actions.setLvlView());
  };

  render() {
    return (
      <div className="GameFrame">
        <div className="GameOverViewTitle">{`game over`}</div>
        <div className="GameOverViewGoal">{`score ${this.props.score}`}</div>
        <div className="GameOverViewGoal">{`score goal ${this.props.lvl * POINT_PER_LVL}`}</div>
        <div className="GameOverViewGoal">{`score global ${this.props.scoreGlobal} `}</div>
        <ButtonSaveRecord />
        <button onClick={() => this.startGame()} className="GameBtn">
          play again
        </button>
        <button onClick={() => this.backHome()} className="GameBtn">
          back home
        </button>
      </div>
    );
  }
}

function mapStateToProps(state, props) {
  return {
    lvl: state.game.lvl,
    score: state.game.score,
    scoreGlobal: state.game.scoreGlobal,
  };
}

export default connect(mapStateToProps)(GameOverView);
